footer {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    color: #bbb;
}

footer a {
    text-decoration: underline;
    color: var(--highlight);
}

footer .icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

footer .icons img {
    margin-top: 5px;
    margin-right: 10px;
}
