.staticPage {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 100vw;
    min-height: 100vh;
}

.staticPage .logo {
    width: calc(min(630px, 40vw));
    max-width: 630px;
    margin-top: calc(min(5vw, 75px));
    margin-bottom: calc(min(5vw, 75px));
}

.staticPage a {
    text-decoration: underline;
    color: var(--highlight);
}

.staticPage .contents {
    width: calc(min(850px, 80vw));
    max-width: 850px;
    padding: 15px 25px;
    background: #00000080;
}

.staticPage .paymentIcons {
    margin-top: 20px;
    text-align: center;
}

.staticPage .paymentIcons img {
    height: 40px;
    margin-right: 10px;
}

.staticPage footer {
    margin-top: calc(min(5vw, 75px));
}

.staticPage > div {
    position: relative;
}

.staticPage .back {
    top: 2em;
}
