.main {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 100vw;
    min-height: 100vh;
}

.main .center {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.main .logo {
    width: calc(min(630px, min(80vh, 80vw)));
}

.main .controls {
    font-size: 22pt;
    display: flex;
    flex-direction: row;
    margin-top: 10%;
}

.main .controls > div {
    position: relative;
    margin-right: var(--margin);
    cursor: pointer;
    --margin: 50px;
}

.main .controls .downloadButton {
    margin-right: calc(var(--margin) + 20px);
}

.main .controls > div:last-child {
    margin-right: 0;
}

.main .controls > div > span {
    font-size: 50%;
    position: absolute;
    top: 10px;
    right: -20px;
}

.main .downloadDropDown {
    font-size: 14pt;
    position: absolute;
    top: calc(100% + 15px);
    width: 320px;
    padding: 0 15px 15px;
    background: #2222229e;
    z-index: 10;
}

.main .downloadDropDown a {
    display: block;
    margin-top: 15px;
}

.main .downloadDropDown a span {
    color: white;
}

.main .vk {
    margin-top: 10%;
    filter: drop-shadow(2px 3px 1px black);
}

.main footer {
    margin-top: 10%;
}
