@font-face {
    font-family: 'Minecraft';
    src: url('../minecraft.ttf') format('truetype');
}

body.prostolauncher-theme {
    --highlight: yellow;
}

body.prostolauncher-theme {
    font-family: Minecraft, sans-serif;
    color: white;
    background: #111823 url("prostolauncher-background.jpg") no-repeat;
    background-size: 100% auto;
    text-shadow: 3px 3px 0 black;
}

body.prostolauncher-theme a {
    color: white;
}

body.prostolauncher-theme footer {
    font-family: 'Exo 2', sans-serif;
    text-shadow: none;
}

body.prostolauncher-theme .main .downloadDropDown a {
    color: var(--highlight);
}

body.prostolauncher-theme .staticPage {
    font-family: 'Exo 2', sans-serif;
    text-shadow: 1px 1px 0 black;
}

body.prostolauncher-theme .staticPage h1,
body.prostolauncher-theme .staticPage h2,
body.prostolauncher-theme .staticPage h3 {
    font-family: Minecraft, sans-serif;
}

body.prostolauncher-theme .shop {
    text-shadow: 1px 1px 0 black;
}

body.prostolauncher-theme .shop .center div>h1 {
    text-shadow: 3px 3px 0 black;
}

body.prostolauncher-theme .shop input,
body.prostolauncher-theme .shop button,
body.prostolauncher-theme .shop select {
    color: white;
    text-shadow: 2px 2px 0 black;
}

body.prostolauncher-theme .shop input:disabled {
    color: #ccc;
}

body.prostolauncher-theme .shop input,
body.prostolauncher-theme .shop select {
    border: 2px solid #777;
    background: #444;
}

body.prostolauncher-theme .shop button {
    padding: 15px 10px;
    border-top: 4px solid #86ff2c;
    border-right: 6px solid #368000;
    border-bottom: 6px solid #368000;
    border-left: 4px solid #86ff2c;
    background: #59d200;
    text-shadow: 3px 3px 0 black;
}

body.prostolauncher-theme .shop button:disabled {
    color: #d4d4d4;
    border-top: 4px solid #bbbbbb;
    border-right: 6px solid #676767;
    border-bottom: 6px solid #676767;
    border-left: 4px solid #bbbbbb;
    background: #a0a0a0;
    text-shadow: 3px 3px 0 #777777;
}

body.prostolauncher-theme .terms-checkbox input:checked {
    background: var(--highlight);
    background: radial-gradient(circle at 50%, var(--highlight) 0%, var(--highlight) 45%, #444 55%, #444 100%);
}
