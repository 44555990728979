.shop {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 100vw;
    min-height: 100vh;
}

.shop .center {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: calc(min(630px, min(80vh, 80vw)));
    max-width: 630px;
}

.shop .center div {
    position: relative;
}

.shop .center>a {
    display: block;

    margin-bottom: 5%;
}

.shop .center div>* {
    margin-bottom: 5%;
}

.shop .logo {
    width: 100%;
}

.shop .center div>h1 {
    margin-top: 0;
    text-align: center;
}

.shop input, .shop button, .shop select {
    font-family: inherit;
    font-size: 18pt;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.shop select {
    padding-bottom: 7px;
}
