html, body {
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

body {
    overflow-x: hidden;
}

a {
    cursor: pointer;
    text-decoration: none;
}
