.terms-checkbox {
    margin-bottom: 8px;
}

.terms-checkbox input {
    display: inline-block !important;
    width: unset !important;
    margin: 0 6px 0 0 !important;
    margin-bottom: -0.25em !important;
}

.terms-checkbox label {
    line-height: 150%;
    display: inline;
    color: #b7b7b7;
}

.terms-checkbox a {
    text-decoration: underline;
}
