body.dcubes-theme {
    font-family: Montserrat, sans-serif;
    color: white;
    background: #190e3e url("dcubes-background.jpg") no-repeat;
    background-size: 100% auto;
}

body.dcubes-theme {
    --highlight: #9302e1;
}

body.dcubes-theme a {
    color: white;
}

body.dcubes-theme .logo img {
    max-height: 280px;
}

body.dcubes-theme .main .downloadDropDown a {
    color: #a79fff;
}

body.dcubes-theme .staticPage {
    font-family: Montserrat, sans-serif;
}

body.dcubes-theme .shop input,
body.dcubes-theme .shop button,
body.dcubes-theme .shop select {
    color: white;
    border: 0;
    background: #322c6a;
}

body.dcubes-theme .shop button {
    border-bottom: 4px solid #625999;
}

body.dcubes-theme .terms-checkbox input:checked {
    background: #fff;
    background: radial-gradient(circle at 50%, #fff 0%, #fff 45%, #322c6a 55%, #322c6a 100%);
}
