.logo {
    display: flex;
    padding-top: 15px;
}

.logo img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}
