.back {
    position: absolute;
    top: 0;
    left: -70px;
    display: block;
    width: 30px;
    height: 40px;
    cursor: pointer;
    background: url("back.png") no-repeat;
    background-size: contain;
    filter: drop-shadow(0px 2px 1px #000);
}
